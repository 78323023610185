














import { Component } from 'vue-property-decorator';

import Field from '@/shared/resources/components/forms/fields/Field.vue';

@Component
export default class InputField extends Field {
}
