





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { Validation as VueValidation } from 'vuelidate';

import Validation from '../../../lib/support/validation/Validation';

@Component
export default class FormField extends Vue {
  /**
   * Props
   */
  @Prop() private label?: string;
  @Prop() private name!: string;
  @Prop() private hint?: string;
  @Prop({ default: 'full' }) private width!: string;
  @Prop() private validation?: VueValidation;
  @Prop() private description?: string;
  @Prop({ default: false, type: Boolean }) private required!: boolean;
  @Prop({ default: false, type: Boolean }) private disableLabel?: boolean;

  /**
   * Getters
   */
  private get isValid(): boolean {
    return !!this.validation && !this.validation.$invalid;
  }

  private get labelCols(): number | null {
    return this.label ? 3 : null;
  }

  private get validationErrors(): string[] {
    if (!this.validation) {
      return [];
    }

    return Validation.translateInvalid(this.validation);
  }

  private get errorsString(): string | null {
    if (!this.displayErrors) {
      return null;
    }

    return this.validationErrors.join('. ');
  }

  /**
   * Display getters
   */
  private get displayLabel(): boolean {
    return !!this.label;
  }

  private get displayAsterisk(): boolean {
    return this.required;
  }

  private get displayHint(): boolean {
    return !!this.hint;
  }

  private get displayErrors(): boolean {
    if (!this.validation) {
      return false;
    }

    return this.validation.$dirty && !!this.validationErrors.length;
  }
}
