

































































import { Vue, Component, Prop } from 'vue-property-decorator';

import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';
import ContractorCoreModel from '@/modules/contractors/models/ContractorCoreModel';
import FormField from '@/shared/resources/components/forms/FormField.vue';
import InputField from '@/shared/resources/components/forms/fields/InputField.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';
import { Validation } from 'vuelidate';

@Component({
  components: {
    GridCol,
    GridRow,
    InputField,
    FormField,
  },
})
export default class DeliveryDataFieldset extends Vue {
  /**
   * Props
   */
  @Prop() private order!: OnlineOrderCoreModel;
  @Prop() private validation!: Validation;
}
